<template>
  <div class="gallery-hldr clearfix" :class="{'p-1 m-0':parent === 'booking'}">
    <div id="gallery" class="content">
      <agile class="main slideshow-container" ref="main" :options="options" @after-change="afterChange">
        <div class="slide" v-for="(slide, index) in slideList"
        :key="index" :class="parent === 'booking'? `from-booking slide--${index}`: `slide--${index}`">
          <img v-bind="mainProps" :src="slide" alt="" @error="showDefaultImage">
        </div>

        <template slot="prevButton">
          <i class="fas fa-chevron-left"></i>
        </template>
        <template slot="nextButton">
          <i class="fas fa-chevron-right"></i>
        </template>
      </agile>

      <div class="thumbnails" v-if="slideList.length > 1 && parent === 'product'">
        <div class="slide--thumbniail" :class="{'slide--thumbniail-active': index === currentIndex}" v-for="(slide, index) in slideList"
          :key="index" @click="$refs.main.goTo(index)">
          <img v-bind="mainProps" :src="slide" alt="" @error="showDefaultImage">
        </div>
      </div>

      <agile class="thumbnails from-booking" dir="ltr"
      v-if="parent === 'booking'" :options="options2" ref="thumb">
        <div class="slide--thumbniail" :class="{'agile__slide--active': index === currentIndex}"
        v-for="(slide, index) in slideList" :key="index" @click="$refs.main.goTo(index);$refs.thumb.goTo(index)">
          <img :src="slide" alt="" @error="showDefaultImage">
        </div>
      </agile>

    </div>
  </div>
</template>
<script>

export default {
  components: {
    Agile: () => import('@/components/atoms/agile/Agile'),
  },
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
    parent: {
      type: String,
      default: 'product',
    },
  },
  data() {
    return {
      options: {
        dots: false,
        fade: true,
        navButtons: true,
        // rtl: true,
        speed: 500,
        slidesToshow: 8,
      },
      options2: {
        dots: false,
        // rtl: true,
        centerMode: true,
        navButtons: false,
        slidesToShow: 3,
      },
      slideList: [],
      currentIndex: 0,
      mainProps: {
        fluidGrow: true,
        blank: true,
        blankColor: '#bbb',
      },
    };
  },
  beforeMount() {
    const s = [
      'assets/img/search/hotel2.jpg',
      'assets/img/search/hotel3.jpg',
      'assets/img/search/hotel4.jpg',
      'assets/img/search/hotel5.jpg',
      'assets/img/search/room-1.jpg',
    ];
    this.slideList = (this.slides.length === 0) ? s : this.slides;
  },
  mounted() {
  },
  methods: {
    afterChange({ currentSlide }) {
      this.currentIndex = currentSlide;
      if (this.$refs.thumb) this.$refs.thumb.goTo(this.currentIndex);
    },
    showDefaultImage(ev) {
      ev.target.src = '/assets/img/after-booking-img2.jpg';
    },
  },
};
</script>

<style scoped>
  @media (max-width: 479px){
    .holiday-hotel-details, .facilitie-list{
      display: none;
    }
  }
</style>

<style lang="sass">
.main
  margin-bottom: 30px

.thumbnails
  clear: both
  margin: 0
  padding: 0
  height: 210px
  overflow-x: auto

.thumbnails.from-booking
  width: calc(100% + 10px)
  height: auto
  margin: 0 -5px

  .slide--thumbniail
    margin: 0
    padding: 0
    img
      cursor: pointer
      height: 55px
      padding: 3px 3px
      transition: opacity 0.3s
      width: 100%
      max-width: fit-content
  .agile__slide--active
    opacity: 1

// Basic VueAgile styles
.agile
  &__nav-button
    background: transparent
    top: calc(50% - 20px)
    border: none
    color: #fff
    cursor: pointer
    font-size: 40px
    transition-duration: .3s

    &--prev
      right: auto
      left: 0
    &--next
      left: auto
      right: 0

    .thumbnails &
      position: absolute
      top: 50%
      transform: translateY(-50%)

      &--prev
        right: -45px

      &--next
        left: -45px

    &:hover
      color: #ccc
      box-shadow: none

  &__dot
    margin: 0 10px

    button
      background-color: #eee
      border: none
      border-radius: 50%
      cursor: pointer
      display: block
      height: 10px
      font-size: 0
      line-height: 0
      margin: 0
      padding: 0
      transition-duration: .3s
      width: 10px

    &--current,
    &:hover
      button
        background-color: #888

// Slides styles
.slide.from-booking
  height: 150px

#gallery .slide
  align-items: center
  box-sizing: border-box
  color: #fff
  display: flex
  height: 450px
  justify-content: center

  &--thumbniail
    cursor: pointer
    transition: all .3s
    margin: 5px 2px
    padding: 2px
    display: inline-flex
    border: 1px solid #ccc
    opacity: .5

    &:hover
      box-shadow: gray 1px 1px 10px

    img
      height: 90px
      max-width: 99px

    &-active
      opacity: 1

  img
    height: 100%
    object-fit: cover
    object-position: center
    width: 100%

@media (max-width: 479px)
  .main
    margin-bottom: 0

    .slide
      height: 200px

  .thumbnails
    height: 80px

    .slide--thumbniail
      width: 18.5%
      height: auto
      margin: 1px

      &-active
        border: 2px solid black
        padding: 1px

      img
        max-width: 100%
        width: 100%
        height: 30px
</style>
